//
// Tables
//

// Make this visible. In old Plone 4 website this was the class for a
// borderless table.
table.invisible {
  visibility: visible;
  width: initial;
  max-width: initial;
  border: 0;
}

table.invisible th,
table.invisible td {
  border: 0;
}

#content {
  table.invisible,
  table.plain {
    margin-bottom: 1em;
  }

  table.invisible th,
  table.invisible td,
  table.plain th,
  table.plain td {
    padding: 0.5em 1em;
    vertical-align: top;
  }
}
