@import "tables";
@import "layout";

//
// Frontpage
//

body.section-front-page #content-core table img {
  width: initial;
  max-width: 100%;
}

body.section-front-page #content-core table.invisible-grid {
  border: 0;
}

//
// Clusterfolder (with map)
//

#esca-map {
  border: 1px solid #000;
}

.esca-filter-form .formControls {
  display: none;
}

.esca-filter-form .form-label {
  font-weight: 700;
}

.esca-filter-form .form-select {
  width: auto;
}

//
// cluster Listing
//

#cluster-listing .status-invalid {
  opacity: 0.4;
}

#cluster-listing th {
  background-color: #fafafa;
}

#cluster-listing tr:hover td {
  background-color: #fcfaef;
}

.esca-filterbox {
  padding: 15px;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
  margin-bottom: 15px;
}
.esca-filterbox .esca-filter-form {
  display: flex;
}

.esca-filterbox .esca-filter-form .field + .field {
  margin-left: 10px;
}

.esca-filterbox .filter-text {
  margin-bottom: 10px;
}

input.js-filter-clusterlist {
  box-sizing: border-box;
  border-radius: 3px;
  border: 2px solid #d7dce0;
  box-shadow: none;
  font-size: 13px;
  margin: 0;
  padding: 8px 6px;
  width: 100%;
}

input.js-filter-clusterlist:hover,
input.js-filter-clusterlist:active,
input.js-filter-clusterlist:focus {
  border-color: #1476b7;
  cursor: pointer;
}

//
// Helper
//

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-strong {
  font-weight: bold;
}

.text-nowrap {
  white-space: nowrap;
}
