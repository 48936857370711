//
// Layout
//

#content-header {
  border-bottom: 1px solid $color-esca-primary;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: calc(1rem + 10px);
}

#portal-logo-wrapper {
  padding-bottom: 0;
}

#portal-logo {
  margin-bottom: -10px;
}

#portal-footer-wrapper {
  color: $body-color;
  background: transparent;
}

#portal-breadcrumbs {
  background-color: transparent;
  margin-bottom: 0.5rem;
}

#portal-breadcrumbs > .container {
  padding-left: 0;
  padding-right: 0;
}

#portal-breadcrumbs .breadcrumb {
  margin: 0;
  padding-left: 0;
}

article > header {
  margin-bottom: 0;
}

//
// Sidebar navigation
//
.portlet.portletNavigationTree {
  box-shadow: none;
  border: 0;
  border-radius: 0;

  ul.navTree {
    background: #ddd;
  }

  ul.navTree .navTreeLevel1 {
    background: #f6f6f9;
  }

  ul.navTree .navTreeItem > a {
    border: 0;
    border-top: 1px solid #fff;
    color: #888;
    font-weight: bold;
    text-decoration: none;
  }

  ul.navTree .navTreeItem > a:hover {
    color: $color-esca-primary;
    background: initial;
  }

  .card-body .navTreeCurrentNode > a::after {
    color: #777;
  }
}

#content-header .navbar-expand-lg .navbar-toggler {
  display: none;
}
